import styled from 'styled-components'
import { Media } from '../theme'
const Wrapper = styled.div`
  max-width: 1200px;
  width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${Media.phone`
    max-width:100vw;
    min-width: initial;
    margin: initial;
    padding-right:  0.3rem;
    padding-left:  0.3rem;
  `}
`
export const LgWrapper = styled(Wrapper)`
  max-width: 1400px;
  width: 1400px;
`
export const LargeWrapper = styled(Wrapper)`
  ${p => p.theme.Hack.desktopForeign`
   max-width: 1400px;
  width: 1400px;
  `}

  ${p => p.theme.Hack.desktopEn`
      max-width: 1400px;
      width: 1400px;
    `}
`
export default Wrapper
