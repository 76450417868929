import React, { useRef, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useMedia } from '@raysync/common/hooks'
import { Billboard, LgWrapper, Button, ModalPortal, CloseIcon } from '@raysync/common/components'
import { Container, BannerContainer, ModalWrap, ButtonGroup } from './atoms'
import PlaySvg from '@raysync/common/components/svg/home/play'

const bannerItems = [
  '传输速度比FTP/HTTP提升100倍',
  '支持亿级文件量、PB级大文件高速传输',
  '跨区域、跨国大数据迁移/归档',
  '支持本地及主流公有云部署',
]
const VideoBanner = ({}) => {
  const isMobile = useMedia()
  const videoRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const play = () => {
    setTimeout(() => {
      videoRef.current && videoRef.current.play()
    }, 200)
  }
  const pause = () => {
    videoRef.current && videoRef.current.pause()
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { eq: "video.png" }) {
            ...fluidImage
          }
          mobileBanner: file(relativePath: { eq: "video_mobile.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ banner, mobileBanner }) => {
        return (
          <>
            <Container>
              <LgWrapper>
                <BannerContainer>
                  <h1>
                    企业大数据、海量
                    {isMobile && <br />}
                    文件<span>高速传输</span>系统
                  </h1>
                  <p>安全、稳定、又可靠的大文件传输方式</p>
                  <ul>
                    {bannerItems.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                  <ButtonGroup>
                    <Button
                      rel='nofollow'
                      as='a'
                      id='RequestFreeTrial'
                      size='large'
                      target='_blank'
                      onClick={() => {
                        window.open('/apply', '_blank')
                      }}
                    >
                      申请免费试用
                    </Button>
                    <Button
                      rel='nofollow'
                      id='RequestFreeTrial'
                      size='large'
                      color='white'
                      variant='outlined'
                      onClick={() => {
                        play()
                        setIsVisible(true)
                      }}
                    >
                      <PlaySvg />
                      观看视频
                    </Button>
                  </ButtonGroup>
                </BannerContainer>
              </LgWrapper>
              <Billboard fluid={isMobile ? mobileBanner.childImageSharp.fluid : banner.childImageSharp.fluid} />
            </Container>
            {isVisible && (
              <ModalPortal
                hideHeader
                visible={isVisible}
                spotlightStyle={{ padding: 0 }}
                contentStyle={{ padding: 0, borderRadius: 0, boxShadow: 'unset', minWidth: 'unset' }}
                onClose={() => {
                  pause()
                  setIsVisible(false)
                }}
              >
                <ModalWrap>
                  <CloseIcon
                    onClick={() => {
                      pause()
                      setIsVisible(false)
                    }}
                  />
                  <video controls preload='none' disablePictureInPicture controlsList='nodownload' ref={videoRef}>
                    <source
                      type='video/mp4'
                      src='https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/Raysync%20introduction%20V7.mp4'
                    />
                    <track kind='captions' />
                  </video>
                </ModalWrap>
              </ModalPortal>
            )}
          </>
        )
      }}
    />
  )
}
export default VideoBanner
