import React from 'react'
import Helmet from 'react-helmet'
import { LocaleService } from '../services/locale-service'
const canonicalMap = {
  home: '/',
  product1: '/products/file/',
  product2: '/products/sdk/',
  product3: '/products/vpn/',
  product4: '/products/ftp/',
  product5: '/products/ssh/',
  solution7: '/solutions/data-collaboration/',
  solution6: '/solutions/data-distribution/',
  solution5: '/solutions/data-migration/',
  solution4: '/solutions/data-sync/',
  solution3: '/solutions/large-file-transfer/',
  solution2: '/solutions/multi-transfer/',
  solution1: '/solutions/data-transfer/',
  government: '/solutions/industry-government/',
  media: '/solutions/industry-media/',
  gaming: '/solutions/industry-gaming/',
  marine: '/solutions/industry-marine/',
  tech: '/solutions/industry-tech/',
  ad: '/solutions/industry-ad/',
  financing: '/solutions/industry-financing/',
  gene: '/solutions/industry-gene/',
  car: '/solutions/industry-car/',
  manufacturing: '/solutions/industry-manufacturing/',
  internet: '/solutions/industry-internet/',
  moive: '/solutions/industry-moive/',
  pricing: '/pricing/',
  pay: '/pay-traffic/',
  example: '/casestudies/',
  about: '/about-us/',
  apply: '/apply/',
  agent: '/partners/',
  'get-license': '/get-license/',
  enterprise: '/enterprise/',
}
const Scripts = ({ pageType, path }) => {
  let canonical
  if (canonicalMap[pageType]) {
    canonical = `${canonicalMap[pageType]}`
  }
  if (path) {
    canonical = path.endsWith('/') || path.endsWith('.html') ? path : path + '/'
  }
  const isApplyPage = pageType === 'apply'
  return (
    <Helmet>
      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no,user-scalable=no'></meta>
      {canonical && <link rel='canonical' href={`https://www.raysync.cn${canonical}`} />}
      <meta property='og:image' content='/raysync.png' />
      <meta property='og:url' content={`https://www.raysync.cn${canonical}`} />

      <meta property='og:type' content='website' />
      <script async>
        {`(function(){
                var bp = document.createElement('script');
                var curProtocol = window.location.protocol.split(':')[0];
                if (curProtocol === 'https') {
                    bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
                }
                else {
                    bp.src = 'http://push.zhanzhang.baidu.com/push.js';
                }
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(bp, s);
            })();`}
      </script>
      <script defer>
        {`var _hmt = _hmt || [];
            (function() {
              var hm = document.createElement("script");
              hm.src = "https://hm.baidu.com/hm.js?723d833749492c4078d9ea07f48d9c46";
              var s = document.getElementsByTagName("script")[0]; 
              s.parentNode.insertBefore(hm, s);
            })();`}
      </script>
      <script defer>
        {isApplyPage &&
          `window._agl = window._agl || [];
            (function () {
            _agl.push(
            ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
            );
            (function () {
            var agl = document.createElement('script');
            agl.type = 'text/javascript';
            agl.async = true;
            agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(agl, s);
            })();
          })();`}
      </script>
      <script defer>
        {`(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "k5omwqs6ud");`}
      </script>
      <script>{`var meta = document.querySelector('meta[name="viewport"]');
        meta && meta.setAttribute('content','width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no')`}</script>
    </Helmet>
  )
}
export default Scripts
