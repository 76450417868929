import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { range } from '../../utils'
import {
  SwipeNavWrap,
  SwipeNav,
  SwiperContainer,
  EnSwipeNavWrap,
  EnSwipeNav,
  TouchBarNavWrap,
  TouchBarNav,
  ArrowSwiperWrapper,
  ArrowButton,
  DotNavWrap,
  DotNav,
} from './atoms'

import GtIcon from '@raysync/common/components/svg/gt'

export const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends React.PureComponent {
  state = { index: 0 }
  handleChangeIndex = index => {
    let { onChangeIndex } = this.props
    onChangeIndex && onChangeIndex(index)
    this.setState({ index })
  }
  render() {
    const { index } = this.state
    const { children, activedColorIndex, swiperNavType = 'default', interval = 8000 } = this.props
    const SwiperNav = {
      default: (
        <SwipeNavWrap>
          {range(children.length).map(i => (
            <SwipeNav
              key={i}
              active={i === index}
              activedColor={activedColorIndex === i}
              onClick={this.handleChangeIndex.bind(this, i)}
            />
          ))}
        </SwipeNavWrap>
      ),
      defaultEn: (
        <EnSwipeNavWrap>
          {range(children.length).map(i => (
            <EnSwipeNav
              key={i}
              active={i === index}
              activedColor={activedColorIndex === i}
              onClick={this.handleChangeIndex.bind(this, i)}
            />
          ))}
        </EnSwipeNavWrap>
      ),
      touch: (
        <TouchBarNavWrap activedIndex={index}>
          {range(children.length).map(i => (
            <TouchBarNav
              key={i}
              active={i === index}
              activedColor={activedColorIndex === i}
              onClick={this.handleChangeIndex.bind(this, i)}
            />
          ))}
        </TouchBarNavWrap>
      ),
      dot: (
        <DotNavWrap className='swiper-dot'>
          {range(children.length).map(i => (
            <DotNav
              key={i}
              active={i === index}
              activedColor={activedColorIndex === i}
              onClick={this.handleChangeIndex.bind(this, i)}
            />
          ))}
        </DotNavWrap>
      ),
    }

    return (
      <SwiperContainer>
        <AutoPlaySwipeableViews
          index={index}
          interval={interval}
          enableMouseEvents
          onChangeIndex={this.handleChangeIndex}
        >
          {children}
        </AutoPlaySwipeableViews>
        {SwiperNav[swiperNavType]}
      </SwiperContainer>
    )
  }
}

export default Swiper

export const ArrowSwiper = ({ children }) => {
  const [index, setIndex] = React.useState(0)
  return (
    <ArrowSwiperWrapper>
      <ArrowButton
        active={index > 0}
        isLeft
        onClick={() => {
          if (index > 0) {
            setIndex(index - 1)
          }
        }}
      >
        <GtIcon />
      </ArrowButton>
      <SwipeableViews index={index}>{children}</SwipeableViews>
      <ArrowButton
        active={index < children.length - 1}
        onClick={() => {
          if (index < children.length - 1) {
            setIndex(index + 1)
          }
        }}
      >
        <GtIcon />
      </ArrowButton>
    </ArrowSwiperWrapper>
  )
}
