import React from 'react'
import intl from 'react-intl-universal'
import { Navigation } from '@raysync/common/services'
import { Button, H2 } from '@raysync/common/components'
import { BannerContainer, BannerWrap, BannerDesc, BannerLeft } from '../atoms'
import Video from '../../video'
import styled from 'styled-components'
import { Media } from '@raysync/common/theme'

export const BannerRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${p => (p.right ? p.right + 'px' : 0)};
  overflow: hidden;
  svg {
    height: ${p => (p.isProduct ? '360px' : '400px')};
  }
  ${Media.phone`
    width: 100%;
    svg{
      width: 100%;
      height: unset;
    }
  `}
`
export const BannerButton = styled(Button)`
  margin-top: 20px;
  ${Media.phone`
    display: block;
    margin: .52rem auto .2rem;
  `}
  ${p => p.theme.Hack.desktopEn`
    min-width: 120px;
    min-height: 44px;
    font-size: 20px;
  `}
`
export const BannerTitle = styled(H2)`
  line-height: 40px;
  padding-bottom: 20px;
  ${p => p.theme.Hack.desktopEn`
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
  `}
  ${Media.phone`
    margin: 1rem auto 0;
    max-width: 5.74rem;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `}
`
const BannerVideo = styled(Video)`
  width: 600px;
  height: 340px;
  ${Media.phone`
    width: 100%;
    height: 4rem;
    margin: 0 .3rem;
  `}
`

const CommonBanner = ({ type, pic, mediaType = 'image', videoSrc }) => (
  <BannerContainer>
    <BannerWrap>
      <BannerLeft>
        <BannerTitle>{intl.get(`${type}.banner.title`)}</BannerTitle>
        <BannerDesc>{intl.getHTML(`${type}.banner.desc`)}</BannerDesc>
        <BannerButton
          onClick={() => {
            Navigation.toApply({ type: 'apply', pageType: type })
          }}
        >
          {intl.get(`${type}.banner.button`)}
        </BannerButton>
      </BannerLeft>
      {mediaType === 'image' ? (
        <BannerRight isProduct={/product/gi.test(type)}>{pic}</BannerRight>
      ) : (
        <BannerVideo src={videoSrc} />
      )}
    </BannerWrap>
  </BannerContainer>
)
export default CommonBanner
