import styled from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
import VideoImage from './video.png'
export const Container = styled.div`
  margin-top: 60px;
  padding-top: 60px;
  position: relative;
  height: 520px;
  overflow: hidden;
  z-index: 0;
  ${Media.phone`
    margin-top: .9rem;
    padding:0;
    height: 10.4rem;
    display: flex;
    justify-content: center;
  `}
`
export const BannerContainer = styled.div`
  color: white;
  margin-top: 40px;
  line-height: 1.5;
  h1 {
    text-align: center;
    font-size: 56px;
    margin-bottom: 6px;
    font-weight: bold;
    span {
      font-weight: bold;
      color: ${p => p.theme.color.secondary};
    }
  }
  p {
    text-align: center;
    font-size: 24px;
  }
  ul {
    display: flex;
    justify-content: center;
    margin: 40px auto 56px;
    list-style: none;
    li {
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 42px;
      background: rgba(34, 32, 42, 0.72);
      font-size: 18px;
      margin: 0 8px;
    }
  }
  button {
    margin-top: 40px;
    min-width: 180px;
    border-radius: 20px;
  }
  ${Media.phone`
      margin-top: 1.2rem;
      h1 {
        font-size: .64rem;
        margin-bottom: .16rem;
      }
      p {
        font-size: .3rem;
      }
      ul {
        flex-wrap: wrap;
        margin: .4rem auto;
        li {
          padding: .1rem .32rem;
          gap: unset;
          border-radius: .42rem;
          font-size: .24rem;
          margin: 0 0 .16rem;
        }
      }
      button {
        min-width: 2.4rem !important;
      }
  `}
`
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin: 0 8px;
  }
  button:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 8px;
    }
    :hover {
      background-color: unset;
      color: white;
    }
  }
`

export const BannerLeft = styled(Wrapper)`
  color: white;
  margin-top: 70px;
  h1 {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 30px;
    span {
      color: ${p => p.theme.color.secondary};
    }
  }
  ul {
    list-style: none;
    li {
      line-height: 1;
      display: flex;
      align-items: center;
      margin-top: 18px;
      color: #636772;
      padding: 0;

      &::before {
        display: none;
      }
      svg {
        width: 16px;
        height: 18px;
        margin-right: 12px;
      }
    }
  }
  button {
    margin-top: 40px;
    min-width: 180px;
    border-radius: 20px;
  }
  ${Media.phone`
    margin-top: .7rem;
    padding-left: .75rem;
    padding-right: .75rem;
    h1 {
      font-size: .48rem;
      line-height: 1.2;
      margin-bottom: .3rem;
    }
    ul {
      li {
      margin-top: .1rem;
      svg {
        width: .2rem;
        height: .3rem;
        margin-right: .12rem;
      }
    }
      }
    button {
      display:block;
      min-width: 3.3rem;
      height: .8rem;
      border-radius: .4rem;
      margin: .5rem auto .56rem;
    }
  `}
`
export const BannerRight = styled.div`
  position: relative;
  min-width: 640px;
  height: 460px;
  padding: 20px;
  background: url(${VideoImage}) 100% 100% /100% 100%;
  > div {
    height: 338px;
    width: 600px;
    img {
      width: 40px;
      height: 40px;
      transform: translate(-20px, -20px);
    }
  }
  ${Media.phone`
    min-width: 6rem;
    height: 4.5rem;
    padding: 0;
    padding-top: .2rem;
    >div {
      height: 3.38rem;
      width: 5.6rem;
     
      img[alt="poster"]{
        transform: unset;
      }
    }
  `}
`
export const ModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  touch-action: none;
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0.4);
`
export const ModalWrap = styled.div`
  width: 800px;
  position: relative;
  > video {
    width: 100%;
  }
  > div {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 10;
    padding: 6px;
    background: #272a39;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    svg {
      path {
        stroke: #bcc1c8 !important;
      }
    }
  }
  ${Media.phone`
    width: 90vw;
    > div {
      top: .2rem;
      right: .15rem;
      padding: .12rem;
    }
  `}
`
