import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import Close from './close'
import { Mask, Wrap, Content, Spotlight, Header } from './atoms'

// NOTE: represent noop
const SCP_001_J = () => null
const stop = event => event.stopPropagation()
const setTransformOrigin = (node, value) => {
  node.style.transformOrigin = value
  node.style.webkitTransformOrigin = value
}
const MaskState = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}
const WrapState = {
  entering: {
    transform: 'scale(1)',
    msTransform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    opacity: 1,
  },
  entered: {
    transform: 'scale(1)',
    msTransform: 'scale(1)',
    WebkitTransform: 'scale(1)',
    opacity: 1,
  },
  exiting: {
    transform: 'scale(0)',
    msTransform: 'scale(0)',
    WebkitTransform: 'scale(0)',
    opacity: 0,
  },
  exited: {
    transform: 'scale(0)',
    msTransform: 'scale(0)',
    WebkitTransform: 'scale(0)',
    opacity: 0,
  },
}

class Modal extends React.Component {
  state = { isShow: !!this.props.visible }
  wrapRef = React.createRef()
  componentDidUpdate() {
    const { origin, visible } = this.props
    if (visible && origin && this.wrapRef.current) {
      setTransformOrigin(this.wrapRef.current, `${origin.x}px ${origin.y}px`)
    }
    if (this.props.autoClose) {
      this.timer = setTimeout(() => this.props.autoClose(), 3000)
    }
  }
  componentWillUnmount() {
    this.timer && clearTimeout(this.timer)
  }
  render() {
    const { isShow } = this.state
    const {
      title,
      hideHeader,
      top,
      children,
      onClose,
      visible,
      closable,
      darkTheme,
      spotlightStyle,
      contentStyle,
      minWidth,
    } = this.props
    const displayStyle = { display: isShow ? null : 'none' }
    return (
      <Transition appear in={!!visible} timeout={300} onEnter={this.onEnter} onExited={this.onExited}>
        {state => (
          <React.Fragment>
            <Mask style={{ ...displayStyle, ...MaskState[state] }} className={state} onClick={onClose} />
            <Wrap
              ref={this.wrapRef}
              className={state}
              top={top}
              onClick={closable ? onClose : SCP_001_J}
              style={{ ...displayStyle, ...WrapState[state] }}
            >
              <Content style={contentStyle} onClick={stop} darkTheme={darkTheme} minWidth={minWidth}>
                {!hideHeader && (
                  <Header>
                    {title ? <h6>{title}</h6> : <span></span>}
                    {closable && <Close onClick={onClose} />}
                  </Header>
                )}
                <Spotlight style={spotlightStyle}>{children}</Spotlight>
              </Content>
            </Wrap>
          </React.Fragment>
        )}
      </Transition>
    )
  }
  onEnter = () => {
    this.setState({ isShow: true })
  }
  onExited = () => {
    this.setState({ isShow: false })
  }
}

Modal.propTypes = {
  visible: PropTypes.bool,
  closable: PropTypes.bool,
  title: PropTypes.string,
  darkTheme: PropTypes.bool,
  minWidth: PropTypes.string,
  origin: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onClose: PropTypes.func,
  onComfirm: PropTypes.func,
}

Modal.defaultProps = { visible: false, closable: true, darkTheme: false, minWidth: '600px' }

export default Modal
