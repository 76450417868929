import React from 'react'
import intl from 'react-intl-universal'
import { Navigation } from '@raysync/common/services'
import { Button, H2, LargeWrapper } from '@raysync/common/components'
import { BannerContainer } from '../atoms'
import Video from '../../video'
import styled from 'styled-components'
import { Media } from '@raysync/common/theme'

export const BannerLeft = styled.div`
  min-width: 566px;
  ${Media.phone`
    min-width: unset;
    width: 100%;
  `}
`
export const BannerDesc = styled.p`
  width: 606px;
  font-size: 16px;
  span {
    display: inline-block;
    width: 100%;
  }
  line-height: 32px;
  color: ${p => p.theme.color.text};
  ${Media.phone`
      margin: 0 auto;
      max-width: 100%;
      padding: 0 .3rem;
      font-size: ${p => p.theme.typography.textThin};
      text-align: center;
      line-height: 18px;
      &.zh-CN-solution2 {
        white-space: initial;
      }
    `}
`

export const BannerWrap = styled(LargeWrapper)`
  height: 100%;
  width: 1400px;
  min-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
  `}
`
export const BannerRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${p => (p.right ? p.right + 'px' : 0)};
  overflow: hidden;
  svg {
    height: ${p => (p.isProduct ? '360px' : '400px')};
  }
  ${Media.phone`
    width: 100%;
    svg{
      width: 100%;
    }
  `}
`
export const BannerButton = styled(Button)`
  margin-top: 40px;

  ${Media.phone`
    display: block;
    margin: .52rem auto .74rem;
  `}
  ${p => p.theme.Hack.desktopEn`
    min-width: 160px;
    min-height: 44px;
    font-size: 20px;
  box-shadow: 0px 10px 16px 4px rgba(253, 92, 31, 0.3);
  border-radius: 20px;
  `}
`
export const BannerTitle = styled(H2)`
  line-height: 40px;
  padding-bottom: 30px;
  ${p => p.theme.Hack.desktopEn`
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
  `}
  ${Media.phone`
    margin: 1rem auto 0;
    max-width: 5.74rem;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `}
`
const BannerVideo = styled(Video)`
  width: 600px;
  height: 340px;
  box-shadow: 0px 10px 18px 2px rgba(102, 102, 102, 0.3);
  border-radius: 10px;
  ${Media.phone`
    width: 100%;
    height: 4rem;
    margin: 0 .3rem .3rem;
  `}
`

const FileBanner = ({ videoSrc }) => (
  <BannerContainer>
    <BannerWrap>
      <BannerLeft>
        <BannerTitle>{intl.get(`product1.banner.title`)}</BannerTitle>
        <BannerDesc>{intl.getHTML(`product1.banner.desc`)}</BannerDesc>
        <BannerButton
          onClick={() => {
            Navigation.toApply({ type: 'apply', pageType: 'product1' })
          }}
        >
          {intl.get(`product1.banner.button`)}
        </BannerButton>
      </BannerLeft>
      <BannerVideo preload='auto' src={videoSrc} />
    </BannerWrap>
  </BannerContainer>
)
export default FileBanner
