import React, { Component, Fragment } from 'react'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'
import { LocaleService } from '../../services'
import { useScroll } from '../../hooks'
import { ContactBarContainer, PopoverContainer, ContactItem, PopoverText, NavAnchor } from './atoms'

import Skype from '../svg/skype'
import WeChat from '../svg/weixin'
import Email from '../svg/new-email'
import Top from '../svg/enHome/up'
import Qrcode from './images/qrcode.png'

function ScrollHandle({ handler }) {
  useScroll(handler)
  return null
}

class ContactBar extends Component {
  state = {
    isLeaveTop: false,
  }
  backToTop = e => {
    const { onClick } = this.props
    e.stopPropagation()
    const result = document.querySelector('main')
    window.location.hash = ''
    result.scrollIntoView()
    if (onClick) {
      onClick()
    }
  }
  openContactWindow = e => {
    e.stopPropagation()
    window.open(
      'http://p.qiao.baidu.com/cps/chat?siteId=11711256&userId=8049670',
      'qiao.baidu',
      'location=no,status=no,scrollvars=no,width=750,height=600',
    )
  }

  handleScroll = e => {
    this.setState({ isLeaveTop: e.target.scrollingElement.scrollTop >= 500 })
  }

  render() {
    let { isLeaveTop } = this.state
    return (
      <Fragment>
        <ContactBarContainer>
          <ScrollHandle handler={this.handleScroll} />

          <ContactItem hasText>
            {/* <Email /> */}
            {/* <PopoverContainer style={{ marginRight: 10 }}> */}
            <div className='hover-container'>
              <Email />
              <NavAnchor
                style={{ fontSize: 14 }}
                rel='noopener noreferrer nofollow'
                href={`mailto:${intl.get('app.contact.email.value')}`}
                className='nav-anchor'
              >
                {`${intl.get('app.footer.contact.email')}: ${intl.get('app.contact.email.value')}`}
              </NavAnchor>
            </div>

            {/* </PopoverContainer> */}
          </ContactItem>

          {LocaleService.isForeign ? (
            <ContactItem>
              <a href='skype:live:.cid.cf62d70f1d1215c6?chat'>
                <Skype />
              </a>
            </ContactItem>
          ) : (
            ''
          )}
          <ContactItem style={{ overflow: 'visible' }}>
            <WeChat />
            <PopoverContainer>
              <div>
                <PopoverText>合作请加商务微信</PopoverText>
                <img src={Qrcode} alt='wechat qrcode' />
              </div>
            </PopoverContainer>
          </ContactItem>

          {isLeaveTop && (
            <ContactItem onClick={this.backToTop}>
              <Top />
            </ContactItem>
          )}
        </ContactBarContainer>
      </Fragment>
    )
  }
}

ContactBar.propTypes = {
  onClick: PropTypes.func,
}
export default ContactBar
